<!-- 
	This is the DataTables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>

	<div>

        <div v-if="!loadingPage">
        
            <a-card :bordered="false" class="header-solid mb-24 px-25" :bodyStyle="{padding: 0, paddingTop: '16px'}">
                <a-row class="mx-25 pt-10">
                    <a-col :span="12">
                        <h5 class="font-semibold">Classroom Details</h5>
                    </a-col>
                    <a-col :span="12" class="text-right">
                        <a-button id="txtBtn" type="text" class="pb-0 pt-0 mb-10 mr-10" size="large" style="color: #FB8C00;" @click="onEditClassroomDetails">
                            <a-icon type="form" /> Edit Basic Details
                        </a-button>
                        <a-button type="primary" href="/student-management/add-student" class="px-20 mb-10">
                            <a-icon type="arrow-left" theme="outlined" class="text-white mr-5" />Back 
                        </a-button>
                    </a-col>

                    <a-col :span="24">
                        <a-divider class="mt-0 pt-0"/>
                    </a-col>
                </a-row>

                <a-row type="flex" align="middle" class="mx-25 pb-25">
                    <a-col :span="10" :sm="6" :md="3" :lg="2">
                        <a-avatar :size="74" shape="square" src="images/face-1.jpg" />
                    </a-col>

                    <a-col :span="14" :sm="10" :md="11" :lg="12">
                        <h5 class="font-semibold m-0">
                            {{ classroom.grade != null ? classroom.grade.name : '' }}{{ classroom.section != null && classroom.section.codeName != 'none' ? `${classroom.section.name}` : '' }}
                        </h5>
                        <p class="mb-0 pb-0">Year: <span class="font-semibold">{{ classroom.academicYear != null ? classroom.academicYear.name : '-' }} </span></p>
                        <p class="mb-0 pb-0">Class Teacher: <span class="font-semibold">Mr. Alex John</span> </p>
                    </a-col>

                    <a-col :span="24" :sm="8" :md="10" :lg="10" class="text-right">
                        <small class="mr-5">Status</small>
                        <a-switch default-checked class="bg-success" v-model="classroom.isActive" />
                    </a-col>

                </a-row>

            </a-card>

            
            <div class="mt-15 pt-10">
                    <a-row type="flex" :gutter="24">
                        <a-col :span="24">
                            <a-tabs class="tabs-sliding primary" v-model="activeTab" @change="onTabChange">

                                <a-tab-pane :key="1" tab="Students">
                                    
                                    <a-card class="header-solid px-25 mb-25">
                                        <div class="mx-25 pb-0 mb-0">
                                            <a-row>
                                                <a-col :span="12" class="pt-15">
                                                    <h5 class="font-semibold mb-0 pb-0 mt-10">
                                                        List of  {{ classroom.grade != null ? classroom.grade.name : '' }}{{ classroom.section != null && classroom.section.codeName != 'none' ? `${classroom.section.name}` : '' }} Students
                                                    </h5>
                                                </a-col>

                                                <a-col :span="12" class="pt-15 text-right">
                                                    <a-button type="primary" @click="onCreateClassroomStudent" class="px-20 mb-10">
                                                        <a-icon type="plus" theme="outlined" class="text-white mr-5" />Add Single Student 
                                                    </a-button>
                                                </a-col>

                                                <a-col :span="24">
                                                    <a-divider class="mt-0 pt-0"/>
                                                </a-col>

                                            </a-row>
                                            <div class="mt-15 pt-10">
                                                <a-row type="flex" :gutter="24">
                                                    <a-col :span="24" :md="12">
                                                        <a-select v-model="classroomStudentPagination.perPage" :options="classroomStudentPagination.perPageOptions" style="width: 70px"/>
                                                        <label for="" class="ml-10">entries per page</label>
                                                    </a-col>
                                                    <a-col :span="24" :md="12" class="text-right">
                                                        <a-input-search  
                                                            placeholder="search name..." 
                                                            style="max-width: 250px;" 
                                                            v-model="classroomStudentPagination.search"  
                                                            @search="onClassroomStudentSearchItem">
                                                            <template #suffix v-if="classroomStudentPagination.search != null && classroomStudentPagination.search.length > 0">
                                                                <a-icon type="close" theme="outlined" class="text-danger mr-10"  @click="onClassroomStudentClearSearchItem" />
                                                            </template>
                                                        </a-input-search>
                                                    </a-col>
                                                </a-row>
                                            </div>
                                            <a-row :gutter="24">
                                                <a-col :span="24" :md="24">
                                                    <a-table class="mt-20"  
                                                        size="middle"
                                                        :bordered="false"
                                                        :columns="classroomStudentPagination.columns" 
                                                        :data-source="classroomStudents" 
                                                        :rowKey="record => record.uuid"
                                                        :loading="classroomStudentPagination.loading" 
                                                        :pagination="false">

                                                        <template slot="s_no" slot-scope="s_no, record, index">
                                                            {{ (classroomStudentPagination.currentPage - 1) * classroomStudentPagination.perPage + index + 1 }}
                                                        </template>

                                                        <template slot="fullname" slot-scope="fullname, record">
                                                            {{ record.student != null ? `${record.student.firstName} ${record.student.middleName != null ? record.student.middleName[0].toUpperCase() : ''} ${record.student.lastName}` : '' }}
                                                        </template>

                                                        <template slot="gender" slot-scope="gender, record">
                                                            {{ record.student != null  ? record.student.gender : '-' }}
                                                        </template>

                                                        <!-- <template slot="age" slot-scope="age, record">
                                                            {{ record.student != null && record.student.dateOfBirth != null ? getAge(record.student.dateOfBirth) : '-' }}
                                                        </template> -->

                                                        <template slot="admissionNumber" slot-scope="admissionNumber">
                                                            {{ admissionNumber != null ? admissionNumber : '-' }}
                                                        </template>

                                                        <template slot="admissionDate" slot-scope="admissionDate">
                                                            {{ admissionDate != null ? $Moment(admissionDate).format("DD MMM YYYY") : '-' }}
                                                        </template>

                                                        <template slot="dateOfJoining" slot-scope="dateOfJoining, record">
                                                            {{ record.student != null && record.student.dateOfJoining != null ? $Moment(record.student.dateOfJoining).format("DD MMM YYYY") : '-' }}
                                                        </template>

                                                        <template slot="isActive" slot-scope="isActive">
                                                            <a-badge  
                                                                style="font-size: 10px !important" 
                                                                :count="isActive ? 'Active' : 'Inactive'" 
                                                                :number-style="isActive ? { backgroundColor: '#52C41A', color: '#FFFFFF'} : { backgroundColor: '#D9D9D9', color: '#444', boxShadow: '0 0 0 1px #d9d9d9 inset'}"/>
                                                        </template>
                                                        
                                                        <template slot="createdAt" slot-scope="createdAt">
                                                            {{ $Moment(createdAt).format("DD MMM YYYY") }}
                                                        </template>

                                                        <template slot="action" slot-scope="action, record">
                                                            <a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #FB8C00;" @click="onEditClassroomStudent(record)">
                                                                <a-icon type="form" /> Edit
                                                            </a-button>
                                                            &nbsp;&nbsp;
                                                            <!-- <a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #188C54;" :href="`/student-management/students/view/${record.uuid}`">
                                                                <a-icon type="eye" /> View
                                                            </a-button>
                                                            &nbsp;&nbsp; -->
                                                            <a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #F44335;" @click="showClassroomStudentDeletionConfirmation(record.uuid)">
                                                                <a-icon type="delete" theme="outlined"/> Delete
                                                            </a-button>
                                                        </template>
                                                        
                                                    </a-table>
                                                </a-col>

                                                <a-col :span="12" class="mt-25 text-muted">
                                                    <p class="category">Showing {{classroomStudentFrom + 1}} to {{classroomStudentTo}} of {{classroomStudentPagination.total}} entries</p>
                                                </a-col>
                                                <a-col :span="12" class="text-right mt-25">
                                                    <a-pagination 
                                                        v-model="classroomStudentPagination.currentPage" 
                                                        :pageSize="classroomStudentPagination.perPage"  
                                                        :total="classroomStudentPagination.total" 
                                                        @change="onClassroomStudentCurrentPageChange"/>
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </a-card>

                                </a-tab-pane>

                                <!-- <a-tab-pane :key="2" tab="Teachers">

                                    <a-card class="header-solid px-25 mb-25">
                                        <div class="mx-25 pb-0 mb-0">
                                            <a-row>
                                                <a-col :span="12" class="pt-15">
                                                    <h5 class="font-semibold mb-0 pb-0 mt-10">List of Guardians</h5>
                                                </a-col>

                                                <a-col :span="12" class="pt-15 text-right">
                                                    <a-button type="primary" @click="onCreateGuardian" class="px-20 mb-10">
                                                        <a-icon type="plus" theme="outlined" class="text-white mr-5" />Add Guardian 
                                                    </a-button>
                                                </a-col>

                                                <a-col :span="24">
                                                    <a-divider class="mt-0 pt-0"/>
                                                </a-col>

                                            </a-row>
                                            <a-row :gutter="24">
                                                <a-col :span="24" :md="24">
                                                    <a-table class="mt-20"  
                                                        size="middle"
                                                        :bordered="false"
                                                        :columns="guardianColumns" 
                                                        :data-source="guardians" 
                                                        :rowKey="record => record.uuid"
                                                        :loading="guardianColumns.loading" 
                                                        :pagination="{pageSize: 10}">

                                                            <template slot="s_no" slot-scope="s_no, record, index">
                                                                {{ index + 1 }}
                                                            </template>

                                                            <template slot="fullname" slot-scope="fullname, record">
                                                                {{ record.firstName }} {{ record.middleName != null ? `${record.middleName[0].toUpperCase()}.` : '' }} {{ record.lastName }}
                                                            </template>

                                                            <template slot="relationship" slot-scope="relationship">
                                                                {{ relationship != null ? relationship.name : '-' }}
                                                            </template>

                                                            <template slot="contacts" slot-scope="contacts, record">
                                                                Phone: {{ record.phone }} <br/>
                                                                Email: {{ record.email }}
                                                            </template>
                                                            
                                                            <template slot="createdAt" slot-scope="createdAt">
                                                                {{ $Moment(createdAt).format("DD MMM YYYY") }}
                                                            </template>

                                                            <template slot="action" slot-scope="action, record">
                                                                <a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #FB8C00;" @click="onEditGuardian(record)">
                                                                    <a-icon type="form" /> Edit
                                                                </a-button>
                                                                &nbsp;&nbsp;
                                                                <a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #F44335;" @click="showGuardianDeletionConfirmation(record.uuid)">
                                                                    <a-icon type="delete" theme="outlined"/> Delete
                                                                </a-button>
                                                            </template>
                                                        
                                                    </a-table>
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </a-card>                          
                                </a-tab-pane> -->
                                
                                <!-- <a-tab-pane :key="3" tab="Examinations">

                                    <a-card class="header-solid" :bodyStyle="{padding: '50px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                                        <a href="#" class="text-center text-muted font-bold">
                                            <h6 class="font-semibold text-muted">Empty Tab</h6>
                                        </a>
                                    </a-card>

                                </a-tab-pane> -->
                                
                                <!-- <a-tab-pane :key="4" tab="Attendance">

                                    <a-card class="header-solid" :bodyStyle="{padding: '50px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                                        <a href="#" class="text-center text-muted font-bold">
                                            <h6 class="font-semibold text-muted">Empty Tab</h6>
                                        </a>
                                    </a-card>

                                </a-tab-pane>                -->

                            </a-tabs>

                        </a-col>
                    </a-row>
            </div>

        </div>

        <div v-if="loadingPage">
            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">
                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
                    <a-spin size="large" />
                </a-col>
            </a-row>
        </div>


        <!-- EDIT CLASSROOM FORM MODAL -->
		<a-modal v-model="classroom.showModal" :footer="null">
			<h4 class="text-center">{{ classroom.isCreate ? 'Add Classroom' : 'Edit Classroom'}}</h4>
			<a-form-model
				:model="classroom"
                :form="form"
                class="login-form mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmit">

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Academic Year *"
					:colon="false">
						<a-select 
							show-search
							v-model="classroom.academicYearUuid" 
							placeholder="Select Academic Year"
							:options="academicYears.map(option => ({ label: option.name, value: option.uuid }))" />
				</a-form-item>

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Grade *"
					:colon="false">
						<a-select 
							show-search
							v-model="classroom.gradeUuid" 
							placeholder="Select Grade"
							:options="grades.map(option => ({ label: option.name, value: option.uuid }))" />
				</a-form-item>

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Section *"
					:colon="false">
						<a-select 
							show-search
							v-model="classroom.sectionUuid" 
							placeholder="Select Section"
							:options="sections.map(option => ({ label: option.name, value: option.uuid }))" />
				</a-form-item>

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Description"
					:colon="false">
						<a-textarea :rows="4" placeholder="" v-model="classroom.description"/>
				</a-form-item>

				<a-form-item class="mt-20"> 
					<a-button type="primary" :loading="classroom.loading" html-type="submit" class="">
						{{ classroom.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form-model>
		</a-modal>



        <!--SINGLE CLASSROOM STUDENT FORM MODAL -->
		<a-modal v-model="classroomStudent.showModal" :footer="null">
			<h4 class="text-center">{{ classroomStudent.isCreate ? 'Add Student' : 'Edit Student'}}</h4>
			<a-form-model
				:model="classroom"
                :form="form"
                class="login-form mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmitSingleClassroomStudent">

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Student *"
                    placeholder="Select Student"
					:colon="false">
						<a-select 
							show-search
							v-model="classroomStudent.studentUuid" 
							placeholder="Select Student"
                            :disabled="!classroomStudent.isCreate"
							:options="students.map(option => ({ label: `${option.firstName} ${option.lastName}`, value: option.uuid }))" />
				</a-form-item>

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Admission Number"
					:colon="false">
						<a-input 
							v-decorator="['admissionNumber', { rules: [{ required: true, message: 'Please input your note!' }] }]"
							v-model="classroomStudent.admissionNumber"/>
				</a-form-item>

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Date of Admission"
					:colon="false">
                        <a-date-picker
                            v-model="classroomStudent.admissionDate2" 
                            format="DD MMM YYYY"
                            style="width: 100%"
                            placeholder=""/>
				</a-form-item>

				<a-form-item class="mt-20"> 
					<a-button type="primary" :loading="classroomStudent.loading" html-type="submit" class="">
						{{ classroomStudent.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>

        <!-- GUARDIAN DELETE CONFIRMATION -->
		<a-modal v-model="deleteDataClassroomStudent.showModal" :footer="null" width="416px">

			<a-row :gutters="24" class="">
				<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
					<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
				</a-col>

				<a-col :span="24" :md="21" :lg="21" :xl="21">

					<a-row :gutters="24">
						<a-col :span="24">
							<h6>Do you want to delete this record?</h6>
							<p>Once you delete, you will not be able to recover this inforamtion.</p>
						</a-col>
					</a-row>

					<a-row :gutters="24">
						<a-col :span="24" class="text-right">
							<a-button id="txtBtn" type="text" class="text-secondary" @click="cancelClassroomStudentDeletion">
								Cancel
							</a-button>
							&nbsp;&nbsp;
							<a-button id="txtBtn" :loading="deleteDataClassroomStudent.loading" type="text" class="text-danger" @click="onDeleteClassroomStudent">
								 Delete
							</a-button>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
      
		</a-modal>

	</div>

</template>

<script>

	const classroomStudentColumns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'FULL NAME',
			dataIndex: 'fullname',
			// sorter: (a, b) => a.firstName.length - b.firstName.length,
			// sortDirections: ['descend', 'ascend'],
			scopedSlots: { customRender: 'fullname' },
		},
		{
			title: 'GENDER',
			dataIndex: 'gender',
			scopedSlots: { customRender: 'gender' },
		},
		// {
		// 	title: 'AGE',
		// 	dataIndex: 'age',
		// 	scopedSlots: { customRender: 'age' },
		// },
		{
			title: 'DATE OF JOINING',
			dataIndex: 'dateOfJoining',
			scopedSlots: { customRender: 'dateOfJoining' },
		},
        {
			title: 'ADMISSION NUMBER',
			dataIndex: 'admissionNumber',
			scopedSlots: { customRender: 'admissionNumber' },
		},
        {
			title: 'ADMISSION DATE',
			dataIndex: 'admissionDate',
			scopedSlots: { customRender: 'admissionDate' },
		},
		{
			title: 'STATUS',
			dataIndex: 'isActive',
			scopedSlots: { customRender: 'isActive' },
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			align: "center"
		},
	];

	
	export default {
		components: {
		},
		data() {
			return {
                activeTab: 1,
				academicPagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10
                },
                gurdianPagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10
                },

                classroomStudentPagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10,
                    columns: classroomStudentColumns,
                },

                
                form: this.$form.createForm(this, { name: 'newItemForm' }),

                academicYears: [],
				grades: [],
				sections: [],
				message: '',

                loadingPage: false,

				classroom: {
					uuid: null,
					academicYearUuid: null,
					gradeUuid: null,
					sectionUuid: null,
					description: null,
                    isActive: false, 
                    academicYear: {},
                    grade: {},
                    section: {},
					isCreate: false,
                    disabled: true,
					loading: false,
					showModal: false,
				},

                students: [],

                classroomStudents: [],

                classroomStudent: {
                    classroomUuid: this.$route.params.uuid,
                    students: null,
                    studentUuid: null,
                    admissionNumber: null, 
                    admissionDate: null,
                    admissionDate2: null,
					isCreate: false,
					loading: false,
					showModal: false,
                },

                deleteDataClassroomStudent: {
					uuid: null,
					showModal: false,
                    loading: false,
				},
			
			}
		},
        computed: {
            classroomStudentTo () {
                let highBound = this.from + this.classroomStudentPagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            classroomStudentFrom () {
                return this.classroomStudentPagination.perPage * (this.classroomStudentPagination.currentPage - 1)
            },
        },
		watch: {
            classroomStudentPagination: {
                handler() {
                    this.getClassroomStudents();
                },
            },
            deep: true,
        },
		created() {
			this.getAllDetails();
            this.getActiveTab();
			this.getAcademicYears();
			this.getGrades();
			this.getSections();
            this.getStudents();
		},
		methods: {

            async onTabChange(event) {
                await localStorage.setItem("classroomActiveTab", event.toString())
            },

            async getActiveTab() {
                let activeTab = await localStorage.getItem("classroomActiveTab")

                if(activeTab) {
                    this.activeTab = parseInt(activeTab);
                }
            },

            async getAllDetails() {
                await this.getClassroomDetails();
                await this.getClassroomStudents()
            },

			getClassroomDetails() {
                this.classroom.loading = true;

                let url = `${this.$BACKEND_URL}/classrooms/${this.$route.params.uuid}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.classroom.disabled = true;
                        this.classroom.academicYear = response.data.academicYear;
                        this.classroom.academicYearUuid = response.data.academicYear.uuid;
                        this.classroom.section = response.data.section;
                        this.classroom.sectionUuid = response.data.section.uuid;
                        this.classroom.grade = response.data.grade;
                        this.classroom.gradeUuid = response.data.grade.uuid;
                        this.classroom.description = response.data.description;
                        this.classroom.isActive = response.data.isActive;
                    }
                    this.classroom.loading = false;
                    this.loadingPage = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    this.classroom.loading = false;
                });
            },

            getAcademicYears() {

                const currentPage = 1;
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/academic-years?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.academicYears = response.data.data;
                    }

                }).catch((error) => {

                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                });
            },
	
            getGrades() {

                const currentPage = 1;
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/grades?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.grades = response.data.data;
                    }
                    
                }).catch((error) => {
                    
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                });
            },
	
            getSections() {

                const currentPage = 1;
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/sections?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.sections = response.data.data;
                    }
                    
                }).catch((error) => {
                    
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                });
            },
		

            onEditClassroomDetails() {
                this.classroom.disabled = false;
                this.classroom.showModal = true;
                this.classroom.isCreate = false;
            },

            async handleSubmit() {

                this.classroom.loading = true;
    
                let url = `${this.$BACKEND_URL}/classrooms/${this.$route.params.uuid}`

                this.$AXIOS.patch(url, this.classroom).then(response => {

                    if (response.status >= 200 && response.status < 210) {

                        this.form.resetFields()

                        this.classroom.loading = false;
                        this.classroom.disabled = true;
                        this.classroom.showModal = false;

                        let message = response.data.message
                        let type = "success"

                        this.notify(type, message)

                        this.getClassroomDetails();

                    }
    
                }).catch(error => {
                
                    this.classroom.loading = false;
        
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)
                });
				
			},


            getStudents() {

                const currentPage = 1;
                const perPage = 10000;

                let url = `${this.$BACKEND_URL}/students/?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.students = response.data.data;
                        }else{
                            let message = "There is no data available"
							let type = "success"

                    		this.notify(type, message)
                        }
                    }
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)
                });
            },

            //CLASSROOM STUDENTS

            getClassroomStudents() {
                this.classroomStudentPagination.loading = true;

                const { currentPage, perPage, search } = this.classroomStudentPagination;

                let url = `${this.$BACKEND_URL}/classrooms/student/${this.$route.params.uuid}?search=${search}&page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.classroomStudents = response.data.data;
                        this.classroomStudentPagination.totalPages = response.data.totalPages;
                        this.classroomStudentPagination.total = response.data.total;
                        this.classroomStudentPagination.currentPage = response.data. currentPage;
                    }
                    this.classroomStudentPagination.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    this.classroomStudentPagination.loading = false;
                });
            },


            onClassroomStudentSearchItem() {
				if(this.classroomStudentPagination.search != null && this.classroomStudentPagination.search.length > 0) {
					this.getClassroomStudents()
				}else{
					let message = "Please enter something to search"
					let type = "danger"

					this.notify(type, message)
				}
			},

			onClassroomStudentClearSearchItem() {
				this.classroomStudentPagination.search = '';

				this.getClassroomStudents();
			},

			onClassroomStudentCurrentPageChange(value) {
				this.classroomStudentPagination.currentPage = value;

				this.getClassroomStudents();
			},

			onClassroomStudentPerPageChange(value) {
                this.classroomStudentPagination.perPage = value;

                this.getClassroomStudents();
            },

            onCreateClassroomStudent() {
                this.classroomStudent.uuid = null;
                this.classroomStudent.classroomUuid = this.$route.params.uuid;
                this.classroomStudent.students = null;
                this.classroomStudent.studentUuid = null;
                this.classroomStudent.admissionDate = null;
                this.classroomStudent.admissionDate2 = null;
                this.classroomStudent.admissionNumber = null;
				this.classroomStudent.isCreate = true;
				this.classroomStudent.loading = false;
				this.classroomStudent.showModal = true;
            },

			onEditClassroomStudent(item) {
                this.classroomStudent.uuid = item.uuid;
                this.classroomStudent.classroomUuid = this.$route.params.uuid;
                this.classroomStudent.students = null;
                this.classroomStudent.studentUuid = item.student.uuid;
                this.classroomStudent.admissionDate = item.admissionDate;
                this.classroomStudent.admissionDate2 = item.admissionDate;
                this.classroomStudent.admissionNumber = item.admissionNumber;
				this.classroomStudent.isCreate = false;
				this.classroomStudent.loading = false;
				this.classroomStudent.showModal = true;
            },


            async handleSubmitSingleClassroomStudent() {

				if(this.classroomStudent.isCreate) {

					this.classroomStudent.loading = true;
		
					let url = `${this.$BACKEND_URL}/classrooms/student/single`
					
					this.classroomStudent.uuid = undefined;

                    this.classroomStudent.admissionDate = this.classroomStudent.admissionDate2 != null && this.classroomStudent.admissionDate2.$d != null ? this.$Moment(this.classroomStudent.admissionDate2.$d).format("YYYY-MM-DD") : this.classroomStudent.admissionDate2; 

					this.$AXIOS.post(url, this.classroomStudent).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.form.resetFields()

							this.classroomStudent.uuid = null;
                            this.classroomStudent.classroomUuid = this.$route.params.uuid;
                            this.classroomStudent.students = null;
                            this.classroomStudent.studentUuid = null;
                            this.classroomStudent.admissionDate = null;
                            this.classroomStudent.admissionDate2 = null;
                            this.classroomStudent.admissionNumber = null;
                            this.classroomStudent.isCreate = true;
                            this.classroomStudent.loading = false;
                            this.classroomStudent.showModal = false;

							let message = response.data.message
							let type = "success"

							this.notify(type, message)

						}

						this.getClassroomStudents()

					}).catch(error => {
					
						this.classroomStudent.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						let type = "danger"

						this.notify(type, message)
			
					});

				}else{

					this.classroomStudent.loading = true;
		
					let url = `${this.$BACKEND_URL}/classrooms/student/${this.classroomStudent.uuid}`

                    this.classroomStudent.admissionDate = this.classroomStudent.admissionDate2 != null && this.classroomStudent.admissionDate2.$d != null ? this.$Moment(this.classroomStudent.admissionDate2.$d).format("YYYY-MM-DD") : this.classroomStudent.admissionDate2; 

					this.$AXIOS.patch(url, this.classroomStudent).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.form.resetFields()

							this.classroomStudent.uuid = null;
                            this.classroomStudent.classroomUuid = this.$route.params.uuid;
                            this.classroomStudent.students = null;
                            this.classroomStudent.studentUuid = null;
                            this.classroomStudent.admissionDate = null;
                            this.classroomStudent.admissionDate2 = null;
                            this.classroomStudent.admissionNumber = null;
                            this.classroomStudent.isCreate = true;
                            this.classroomStudent.loading = false;
                            this.classroomStudent.showModal = false;

							let message = response.data.message
							let type = "success"

							this.notify(type, message)

						}

						this.getClassroomStudents()
		
					}).catch(error => {
					
						this.classroomStudent.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						let type = "danger"

						this.notify(type, message)
					});
				}
				
			},

            showClassroomStudentDeletionConfirmation(uuid) {
				this.deleteDataClassroomStudent.uuid = uuid;
				this.deleteDataClassroomStudent.showModal = true;
			},

			cancelClassroomStudentDeletion() {
				this.deleteDataClassroomStudent.uuid = null;
				this.deleteDataClassroomStudent.showModal = false;
			},

			onDeleteClassroomStudent() {

				this.deleteDataClassroomStudent.loading = true;

                let url = `${this.$BACKEND_URL}/classrooms/student/${this.deleteDataClassroomStudent.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDataClassroomStudent.uuid = null;
					this.deleteDataClassroomStudent.showModal = false;
                
                    this.deleteDataClassroomStudent.loading = false;

					let message = response.data.message;
                    let type = "success"

                    this.notify(type, message)

					this.getClassroomStudents();

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    this.deleteDataClassroomStudent.loading = false;
                });
			},

            getAge (birthDate) {
				var years = new Date(new Date() - new Date(birthDate)).getFullYear() - 1970;

				console.log(years)
				console.log(birthDate)
				return years
			},

			notify(type, message) {
				this.$notification.open({
					class: 'ant-notification-' + type,
					message: message,
					duration: 3.0,
					style: {fontSize: "10px !important"}
				});
			},

		},

		
	}

</script>

<style lang="scss">

	#txtBtn, #txtBtn:hover {
		border: none;
	}
    .ant-input[disabled], .ant-select-disabled {
        color: #414141;
        font-weight: 400;
    }
</style>